export default function Waves(props) {
    switch (props.waveType){
        case 'Sawtooth':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 502 26">
                    <path stroke="currentColor" d="M.76 24.214 32.01 1.22v22.994L63.26 1.22v22.994L94.51 1.22v22.994L125.76 1.22v22.994L157.01 1.22v22.994L188.26 1.22v22.994L219.51 1.22v22.994L250.76 1.22v22.994L282.01 1.22v22.994L313.26 1.22v22.994L344.51 1.22v22.994L375.76 1.22v22.994L407.01 1.22v22.994L438.26 1.22v22.994L469.51 1.22v22.994L500.76 1.22v22.994"/>
                </svg>
            )
            // break
        case 'Sine':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 501 25">
                    <path stroke="currentColor" d="M.76 23.863C11.617 23.863 5.528.87 16.385.87c10.856 0 4.768 22.993 15.625 22.993C42.866 23.863 36.778.87 47.635.87c10.856 0 4.768 22.993 15.625 22.993C74.116 23.863 68.028.87 78.885.87c10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993s4.768 22.993 15.625 22.993c10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993 10.856 0 4.768-22.993 15.625-22.993s4.768 22.993 15.625 22.993c10.856 0 4.768-22.993 15.625-22.993 10.856 0 4.768 22.993 15.625 22.993"/>
                </svg>
            )
            // break
        case 'Triangle':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 502 26">
                    <path stroke="currentColor" d="M.76 24.797 16.385 1.803 32.01 24.797 47.635 1.803 63.26 24.797 78.885 1.803 94.51 24.797l15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994 15.625-22.994 15.625 22.994"/>
                </svg>
            )
            // break
        case 'Square':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 502 25">
                    <path stroke="currentColor" d="M63.26 23.73V.739H47.633V23.73H32.01V.738H16.384V23.73H.76m124.999 0V.738h-15.624V23.73H94.51V.738H78.885V23.73H63.26m125 0V.738h-15.625V23.73H157.01V.738h-15.625V23.73H125.76m62.501 0h15.624V.738h15.625V23.73h15.625V.738h15.625V23.73h15.625V.738h15.624V23.73h15.625V.738h15.625V23.73m62.5 0V.738h-15.624V23.73H344.51V.738h-15.625V23.73H313.26m125 0V.738h-15.625V23.73H407.01V.738h-15.625V23.73H375.76m125 0V.738h-15.625V23.73H469.51V.738h-15.625V23.73h-15.624"/>
                </svg>
            )
            // break
        default:
            return
            // break
    }
}